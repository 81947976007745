<template>
  <div>
    <StatusFilterList
      v-bind="params"
      v-model="params.status"
      @change="change"
      :list="list"
    >
      <div class="text-right" slot="opt">
        <a-space :size="20">
          <gf-re-input-search
            @search="change({ name: $event, current: 1 })"
            style="width: 240px"
          >
          </gf-re-input-search>
        </a-space>
      </div>
      <ProCard
        slot="card"
        slot-scope="{ dataSource }"
        :dataSource="dataSource"
        type="survey"
        :showDel="userInfo.policy_privilege"
        @del="change"
        :showStatus="userInfo.policy_privilege"
        @click.native="push(dataSource.ID)"
      >
        <status-box
          :status="dataSource.status"
          :evaluate="dataSource.evaluate"
          :ReviewCount="dataSource.ReviewCount"
          slot="status"
        >
          <span v-if="dataSource.status === 30 && dataSource.evaluate === 0"
            >匹配中</span
          >
          <span
            v-else-if="dataSource.status === 30 && dataSource.evaluate === 1"
          >
            评估中
          </span>
          <span
            v-else-if="
              dataSource.status === 30 &&
              dataSource.evaluate === 2 &&
              !dataSource.ReviewCount
            "
          >
            已完成
          </span>
          <span
            v-else-if="
              dataSource.status === 30 &&
              dataSource.evaluate === 2 &&
              dataSource.ReviewCount
            "
            >已评价</span
          >
          <span v-else-if="dataSource.status === -1">已关闭</span>
        </status-box>
      </ProCard>
    </StatusFilterList>
  </div>
</template>

<script>
import ProCard from "./components/card.vue";

import { getMyPolicyList, getJoinedPolicyList } from "@/api/api/policy";

export default {
  components: { ProCard },
  data() {
    return {
      params: {
        current: 1,
        limit: 5,
        total: 0,
        status: 0,
        name: "",
        filters: [
          { value: "1", name: "待审核" },
          { value: "2", name: "已通过" },
          { value: "3", name: "未通过" },
        ],
      },
      list: [],
    };
  },
  computed: {
    role() {
      return this.$store.state.userInfo.role;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.change();
    if (this.userInfo.policy_privilege) {
      this.$set(this.params, "filters", [
        { value: "1", name: "待审核" },
        { value: "2", name: "已通过" },
        { value: "3", name: "未通过" },
      ]);
    } else {
      this.$set(this.params, "filters", false);
    }
  },
  methods: {
    change(e) {
      const params = this.generateParams(e);
      this.getList(params);
    },
    generateParams(e) {
      Object.assign(this.params, e);

      const { name, status, limit, current } = this.params;

      const temp = {
        status: status ? status : undefined,
        type: 2,
        limit,
        offset: limit * (current - 1),
        name,
      };

      return temp;
    },
    getList(params) {
      if (this.userInfo.policy_privilege) {
        getMyPolicyList(params)
          .then(({ count, rows }) => {
            this.list = rows;
            this.$set(this.params, "total", count);
            let current = this.params.current;
            if (rows.length === 0 && current !== 1) {
              console.log(current, "当前页面没有数据了");
            }
          })
          .catch(() => {
            this.$message.error("获取列表失败");
          });
      } else {
        getJoinedPolicyList(params)
          .then(({ count, rows }) => {
            this.list = rows;
            this.$set(this.params, "total", count);
            let current = this.params.current;
            if (rows.length === 0 && current !== 1) {
              console.log(current, "当前页面没有数据了");
            }
          })
          .catch(() => {
            this.$message.error("获取列表失败");
          });
      }
    },
    push(id) {
      this.$router.push(`/evaluate/evaluate-policy-survey/${id}`);
      this.id = id;
    },
  },
};
</script>